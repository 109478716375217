import React from "react";
import { MenuItem, TextField, TextFieldProps } from "@mui/material";
import payproLogo from "../../pages/Paywall/blocks/assets/paypro.png";
import stripeLogo from "../../pages/Paywall/blocks/assets/stripe.svg";

export type ProviderType = "stripe" | "paypro" | "stripe+paypro";

interface ProviderSelectProps extends Omit<TextFieldProps, "onChange"> {
  value: ProviderType;
  onChange: (value: ProviderType) => void;
  sx?: React.CSSProperties;
}

const ProviderSelect: React.FC<ProviderSelectProps> = ({
  value,
  onChange,
  sx,
  ...props
}) => {
  return (
    <TextField
      select
      value={value}
      onChange={(e) => onChange(e.target.value as ProviderType)}
      sx={{
        "& .MuiSelect-select": {
          height: "48px",
          alignItems: "center",
          display: "flex"
        },
        ...sx
      }}
      {...props}>
      <MenuItem value="stripe" sx={{ height: "42px" }}>
        <img src={stripeLogo} alt="Stripe" width={100} />
      </MenuItem>
      <MenuItem value="paypro" sx={{ height: "42px" }}>
        <img
          src={payproLogo}
          alt="PayPro"
          width={100}
          style={{ marginLeft: "10px" }}
        />
      </MenuItem>
      <MenuItem value="stripe+paypro" sx={{ height: "42px" }}>
        <img src={stripeLogo} alt="Stripe" width={100} /> {" + "}
        <img
          src={payproLogo}
          alt="PayPro"
          width={100}
          style={{ marginLeft: "10px" }}
        />
      </MenuItem>
    </TextField>
  );
};

export default ProviderSelect;

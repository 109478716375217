import React from "react";
import { Box, Paper, TextField } from "@mui/material";

interface Review {
  text: string;
  date: string;
  title: string;
  author: string;
  score: number;
}

interface ReviewsBlockProps {
  parameters: {
    title?: string;
    items: Review[];
  };
  onChange: (param: string, value: unknown) => void;
}

const ReviewsBlock: React.FC<ReviewsBlockProps> = ({
  parameters,
  onChange
}) => {
  const handleReviewChange = (
    reviewIndex: number,
    field: string,
    value: unknown
  ) => {
    const updatedReviews = [...parameters.items];
    const review = { ...updatedReviews[reviewIndex] };

    review.score = 5;

    // Handle nested fields like "providers.stripe.price"
    const fieldParts = field.split(".");
    let current: Record<string, unknown> = review;

    // Regular field handling
    for (let i = 0; i < fieldParts.length - 1; i++) {
      if (!current[fieldParts[i]]) {
        current[fieldParts[i]] = {};
      }
      current = current[fieldParts[i]] as Record<string, unknown>;
    }
    current[fieldParts[fieldParts.length - 1]] = value;

    updatedReviews[reviewIndex] = review;
    onChange("items", updatedReviews);
  };

  // Ensure score is set to 5 when rendering reviews
  parameters.items.forEach((review) => {
    review.score = 5;
  });

  return (
    <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          label="Title"
          value={parameters.title || ""}
          onChange={(e) => onChange("title", e.target.value)}
        />
        {parameters.items.map((review, reviewIndex) => (
          <Box
            sx={{
              marginTop: 2,
              padding: 2,
              border: "1px solid #ccc",
              borderRadius: 1,
              display: "flex",
              flexDirection: "column",
              gap: 2
            }}>
            <TextField
              label="Title"
              value={review.title}
              onChange={(e) =>
                handleReviewChange(reviewIndex, "title", e.target.value)
              }
            />
            <TextField
              label="Text"
              value={review.text}
              onChange={(e) =>
                handleReviewChange(reviewIndex, "text", e.target.value)
              }
            />
            <TextField
              label="Author"
              value={review.author}
              onChange={(e) =>
                handleReviewChange(reviewIndex, "author", e.target.value)
              }
            />
            <TextField
              label="Date"
              value={review.date}
              onChange={(e) =>
                handleReviewChange(reviewIndex, "date", e.target.value)
              }
            />
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default ReviewsBlock;

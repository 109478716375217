const calculateRecommendedWeight = (
  age: number | undefined,
  height: number
) => {
  let result = 0;
  const t = height - 100;
  if (age && age < 30) {
    result = t * 0.9;
  } else if (age && age > 50) {
    result = t * 1.06;
  } else {
    result = t;
  }
  return result.toFixed(0);
};

const getPrognosisWeights = (
  weight: number,
  scaleNotation: string,
  hardcodedValue?: number
) => {
  let firstWeekLoss = 1;
  let secondWeekLoss = 3;
  let thirdWeekLoss = 4;

  if (weight >= 41 && weight <= 60) {
    firstWeekLoss = 1;
    secondWeekLoss = 2;
    thirdWeekLoss = 2;
  } else if (weight >= 61 && weight <= 80) {
    firstWeekLoss = 1;
    secondWeekLoss = 2;
    thirdWeekLoss = 3;
  }

  let metricResult = [
    weight,
    weight - firstWeekLoss,
    weight - secondWeekLoss,
    weight - thirdWeekLoss,
  ];
  if (hardcodedValue) {
    metricResult = [
      weight,
      parseInt((weight - hardcodedValue / 2.205 / 3).toFixed(0)),
      parseInt((weight - (2 * (hardcodedValue / 2.205)) / 3).toFixed(0)),
      parseInt((weight - (3 * (hardcodedValue / 2.205)) / 3).toFixed(0)),
    ];
  }
  const imperialResult = metricResult.map((w) =>
    parseInt((w * 2.205).toFixed(0))
  );
  const result =
    !scaleNotation || scaleNotation === "imperial"
      ? imperialResult
      : metricResult;
  return result;
};

const getPrognosisFinalWeight = (
  weight: string,
  targetWeight: string,
  scaleNotation: string
) => {
  const DAILY_LOSS = 0.4 / 2.205; // 0.4 lbs per day to kg;

  const metricResult = [
    parseInt(weight),
    parseInt((parseInt(targetWeight) + DAILY_LOSS * 14).toFixed(0)),
    parseInt((parseInt(targetWeight) + DAILY_LOSS * 7).toFixed(0)),
    parseInt(targetWeight),
  ];

  const imperialResult = metricResult.map((w: number) =>
    parseInt((w * 2.205).toFixed(0))
  );
  const result =
    !scaleNotation || scaleNotation === "imperial"
      ? imperialResult
      : metricResult;
  return result;
};

const calculateAgeRange = (age: string) => {
  const ranges = ["0-17", "18-24", "25-34", "35-44", "45-54", "55-64"];
  let ageRange;
  const ageInt = parseInt(age);
  ranges.forEach((range) => {
    const values = range.split("-");
    if (ageInt >= parseInt(values[0]) && ageInt <= parseInt(values[1])) {
      ageRange = range;
    }
  });
  if (!ageRange && ageInt >= 65) {
    ageRange = "65+";
  }
  if (!ageRange) {
    ageRange = "other";
  }
  console.log("Calculate age range", age, ranges, ageRange);
  return ageRange;
};

const calculateBMIRange = (bmi: string) => {
  const ranges = ["0-17", "18-24", "25-29", "30-34", "35-39"];
  let bmiRange;
  const bmiInt = parseInt(bmi);
  ranges.forEach((range) => {
    const values = range.split("-");
    if (bmiInt >= parseInt(values[0]) && bmiInt <= parseInt(values[1])) {
      bmiRange = range;
    }
  });
  if (!bmiRange && bmiInt >= 40) {
    bmiRange = "40+";
  }
  if (!bmiRange) {
    bmiRange = "other";
  }
  console.log("Calculate bmi range", bmi, ranges, bmiRange);
  return bmiRange;
};

export {
  calculateRecommendedWeight,
  getPrognosisWeights,
  getPrognosisFinalWeight,
  calculateAgeRange,
  calculateBMIRange,
};

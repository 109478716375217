import { Box } from "@mui/material";
import { lighten } from "@mui/material/styles";
import iphoneFrame from "../../assets/iphone_frame.png";
import { FunnelScreen, Project } from "../../types";
import MultichoiceImageV1 from "./templates/MultichoiceImageV1";
import MultichoiceImageV2 from "./templates/MultichoiceImageV2";
import PromoScreenV1 from "./templates/PromoScreenV1";
import MultichoiceTextV1 from "./templates/MultichoiceTextV1";
import InputAgeV1 from "./templates/InputAgeV1";
import Header from "./templates/Header";
import InputHeightV1 from "./templates/InputHeightV1";
import InputWeightV1 from "./templates/InputWeightV1";
import CalculationV1 from "./templates/CalculationV1";
import WellnessProfileV1 from "./templates/WellnessProfileV1";
import CalculationV2 from "./templates/CalculationV2";
import InfoScreenV1 from "./templates/InfoScreenV1";
import GraphV1 from "./templates/GraphV1";
import GraphV2 from "./templates/GraphV2";
import PromoScreenV2 from "./templates/PromoScreenV2";
import InputEmailV1 from "./templates/InputEmailV1";
import StatementV1 from "./templates/StatementV1";
import ReviewsScreenV1 from "./templates/ReviewsScreenV1";

import "./ScreenPreview.css";

interface ScreenPreviewProps {
  project: Project;
  screen: FunnelScreen;
  parameters: Record<string, any>;
  scale?: string;
}

const ScreenPreview = (props: ScreenPreviewProps) => {
  const { project, screen, parameters, scale } = props;

  return (
    <Box
      sx={{
        // zoom: scale ? scale : null,
        transform: scale ? `scale(${scale},${scale})` : null,
        marginTop: scale ? "-250px" : "initial"
      }}
      id="screenPreview">
      <Box
        sx={{
          backgroundImage: "url(" + iphoneFrame + ")",
          backgroundSize: "354px 699px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "354px",
          height: "699px",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end"
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // border: "1px solid #525252",
            borderRadius: "8px",
            borderBottomLeftRadius: "42px",
            borderBottomRightRadius: "42px",
            overflow: "scroll",
            width: "294px",
            height: "604px",
            marginBottom: "29px",
            "--funnel7-base-color": project.parameters?.color || "#DA4960",
            "--color-primary1": project.parameters?.color || "#DA4960",
            "--funnel7-base-color-light": lighten(
              project.parameters?.color || "#DA4960",
              0.8
            )
          }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              zoom: "0.75"
            }}>
            <>
              {screen.template?.id === "ibKPAjP5MvaONncd9KGUnVUd1BD3" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    padding: "16px 12px",
                    position: "relative"
                  }}>
                  <Header project={project} />
                  <MultichoiceImageV1
                    screenName={screen.name}
                    {...parameters}
                  />
                </Box>
              )}
              {screen.template?.id === "008E8Nzh65VzMTZh2AIYaJxYGjF2" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    padding: "16px 12px",
                    position: "relative"
                  }}>
                  <Header project={project} />
                  <MultichoiceImageV2
                    screenName={screen.name}
                    {...parameters}
                  />
                </Box>
              )}
              {screen.template?.id === "00F8QP8qhmOmodfBjhoyoen0LV93" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    padding: "16px 12px",
                    position: "relative"
                  }}>
                  <Header project={project} />
                  <PromoScreenV1 screenName={screen.name} {...parameters} />
                </Box>
              )}
              {screen.template?.id === "00TxZbVCaLRqJKeiMUS77VKvL0mQ" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    padding: "16px 12px",
                    position: "relative"
                  }}>
                  <Header project={project} />
                  <PromoScreenV1 screenName={screen.name} {...parameters} />
                </Box>
              )}
              {screen.template?.id === "00F8QP8qhmOmodfBjhoyoen0LK66" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    padding: "16px 12px",
                    position: "relative"
                  }}>
                  <Header project={project} />
                  <ReviewsScreenV1 screenName={screen.name} {...parameters} />
                </Box>
              )}
              {screen.template?.id === "00LOeXnWvBQwEH6Qu72XFs8kG8b2" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    padding: "16px 12px",
                    position: "relative"
                  }}>
                  <Header project={project} />
                  <MultichoiceTextV1 screenName={screen.name} {...parameters} />
                </Box>
              )}
              {screen.template?.id === "00LOeXnWvBQwEH6Qu72XFs8kGLL8" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    padding: "16px 12px",
                    position: "relative"
                  }}>
                  <Header project={project} />
                  <StatementV1 screenName={screen.name} {...parameters} />
                </Box>
              )}
              {screen.template?.id === "00Q1l2RBXCMqi5E7pKkUZoQBr4x2" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    padding: "16px 12px",
                    position: "relative"
                  }}>
                  <Header project={project} />
                  <InputAgeV1 screenName={screen.name} {...parameters} />
                </Box>
              )}
              {screen.template?.id === "00RVkix4RgMEawHuoHUy4Zjoedw2" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    padding: "16px 12px",
                    position: "relative"
                  }}>
                  <Header project={project} />
                  <InputHeightV1 screenName={screen.name} {...parameters} />
                </Box>
              )}
              {screen.template?.id === "0103tYcpXCajteMHNQIEKzDOr5g1" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    padding: "16px 12px",
                    position: "relative"
                  }}>
                  <Header project={project} />
                  <InputWeightV1 screenName={screen.name} {...parameters} />
                </Box>
              )}
              {screen.template?.id === "0103tYcpXCajteMHNQIEKzDOr5g2" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    padding: "16px 12px",
                    position: "relative"
                  }}>
                  <Header project={project} />
                  <InputWeightV1 screenName={screen.name} {...parameters} />
                </Box>
              )}
              {screen.template?.id === "00TxZbVCaLRsHoziKUS77VKvT9H2" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    position: "relative",
                    padding: "16px 12px"
                  }}>
                  <Header project={project} />
                  <CalculationV1 screenName={screen.name} {...parameters} />
                </Box>
              )}
              {screen.template?.id === "00TxZbVCaLRsJKeiKUS77VKvT6mQ" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    position: "relative",
                    padding: "16px 12px"
                  }}>
                  <Header project={project} />
                  <WellnessProfileV1 screenName={screen.name} {...parameters} />
                </Box>
              )}
              {screen.template?.id === "01DN6QLvoqV80LIoaQp2K64fjGx2" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    position: "relative",
                    padding: "16px 12px"
                  }}>
                  <Header project={project} />
                  <CalculationV2 screenName={screen.name} {...parameters} />
                </Box>
              )}
              {screen.template?.id === "00YjZvwWyof6fePFbluu7togjU02" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    position: "relative"
                  }}>
                  <Box sx={{ padding: "16px 12px" }}>
                    <Header project={project} />
                  </Box>
                  <InfoScreenV1 screenName={screen.name} {...parameters} />
                </Box>
              )}
              {screen.template?.id === "00cBO41LSAfYcoaklBA7wcdQEC23" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    padding: "16px 12px",
                    position: "relative"
                  }}>
                  <Header project={project} />
                  <GraphV1 screenName={screen.name} {...parameters} />
                </Box>
              )}
              {screen.template?.id === "00qJE3ProtbobWziGk9qQd6NjYg1" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    position: "relative",
                    padding: "16px 12px"
                  }}>
                  <Header project={project} />
                  <GraphV2 screenName={screen.name} {...parameters} />
                </Box>
              )}

              {screen.template?.id === "00h3rlsJY0afUXHY5GNmwH3ef4E2" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    position: "relative"
                  }}>
                  <Box sx={{ padding: "16px 12px" }}>
                    <Header project={project} />
                  </Box>
                  <PromoScreenV2 screenName={screen.name} {...parameters} />
                </Box>
              )}
              {screen.template?.id === "00m6LSjY3Da6F2WLN3zv8uKXkjL2" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    position: "relative",
                    padding: "16px 12px"
                  }}>
                  <Header project={project} />
                  <InputEmailV1 screenName={screen.name} {...parameters} />
                </Box>
              )}
            </>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ScreenPreview;

import React from "react";
import { Box, Paper, Switch, FormControlLabel, TextField } from "@mui/material";
import ImageUpload from "../../../components/ImageUpload";

interface ImageBlockProps {
  parameters: {
    title?: string;
    image?: string;
    fill?: string;
    padding?: string;
  };
  onChange: (param: string, value: unknown) => void;
  projectId?: string;
}

const ImageBlock: React.FC<ImageBlockProps> = ({
  parameters,
  onChange,
  projectId
}) => {
  const handleFillChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange("fill", event.target.checked ? "cover" : "contain");
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          label="Title"
          value={parameters.title || ""}
          onChange={(e) => onChange("title", e.target.value)}
        />
        <FormControlLabel
          control={
            <Switch
              checked={parameters.fill === "cover"}
              onChange={handleFillChange}
            />
          }
          label="Fill Cover"
        />
        <ImageUpload
          label="Image URL"
          value={parameters.image || ""}
          onChange={(url) => onChange("image", url)}
          projectId={projectId}
        />
        <TextField
          label="Padding"
          value={parameters.padding || ""}
          onChange={(e) => onChange("padding", e.target.value)}
        />
      </Box>
    </Paper>
  );
};

export default ImageBlock;

const parseVariables = (text?: string, profile?: Record<string, any>) => {
  let titleParsed = text || "";
  // extract variables like {{variable_name}}
  if (profile) {
    const r = titleParsed.match(/\{[\w]+\}/g);
    if (r) {
      (r as RegExpMatchArray).forEach((state) => {
        const regex = new RegExp(state, "g");
        const stateItem = state.split(/{|}/g)[1];
        console.log("Variable", stateItem, profile?.[stateItem]);
        titleParsed = titleParsed.replace(regex, profile?.[stateItem]);
      });
    }
  }

  // extract HighlightText like **variable_name**
  titleParsed = titleParsed.replace(
    /\*{2}(.*?)\*{2}/g,
    "<span style='color: var(--color-primary1)'>$1</span>"
  );
  return titleParsed;
};

export { parseVariables };

import React from "react";
import {
  Box,
  TextField,
  Paper,
  Typography,
  Button,
  Switch,
  FormControlLabel
} from "@mui/material";
import ImageUpload from "../../../components/ImageUpload";
import ColorChooser from "../../../components/ColorChooser";
import StripePriceDropdown, {
  StripePrice
} from "../../../components/StripePriceDropdown";
import StripeCouponDropdown, {
  StripeCoupon
} from "../../../components/StripeCouponDropdown";
import payproLogo from "./assets/paypro.png";
import stripeLogo from "./assets/stripe.svg";
import PayProProductDropdown, {
  PayProProduct
} from "../../../components/PayProProductDropdown";
import ProviderSelect from "../../../components/ProviderSelect/ProviderSelect";

interface Plan {
  title: string;
  billingPeriod: string;
  highlighted: boolean;
  highlighted_text: string;
  productName: string;
  predicted_ltv: number;
  perday_text: string;
  recurringPriceCrossedOut: boolean;
  showDiscountBadge: boolean;
  soldout: boolean;
  noteText: string;
  paymentFormNoteText: string;
  providers: {
    stripe?: {
      price?: StripePrice;
      coupon?: StripeCoupon;
      phases?: {
        iterations?: number;
        items: {
          price?: StripePrice;
          coupon?: StripeCoupon;
          quantity: number;
        }[];
      }[];
    };
    paypro?: {
      product?: PayProProduct;
      "coupon-code-to-add": string;
      "page-template": number;
    };
  };
  url: {
    name: string;
    description: string;
  };
}

interface PlansBlockProps {
  projectId: string;
  parameters: {
    backgroundColor: string;
    goals: { text: string; emoji: string }[];
    image?: string;
    index: number;
    plans: Plan[];
    title: string;
    securedNoteText: string;
    ctaButtonText: string;
    agreementMessage: string;
    provider: "stripe" | "paypro" | "stripe+paypro";
    remarket: {
      banner: {
        image: string;
        title: string;
        buttonText: string;
        upperText: string;
        lowerText: string;
      };
    };
    NEXT_PUBLIC_STRIPE_KEY: string;
  };
  onChange: (param: string, value: unknown) => void;
}

const sanitizeProviders = (
  plan: Plan,
  provider: "stripe" | "paypro" | "stripe+paypro"
) => {
  const newPlan = { ...plan };

  if (provider === "stripe") {
    newPlan.providers = {
      stripe: newPlan.providers.stripe || {
        price: undefined,
        coupon: undefined
      }
    };
  } else if (provider === "paypro") {
    newPlan.providers = {
      paypro: newPlan.providers.paypro || {
        product: undefined,
        "coupon-code-to-add": "",
        "page-template": 0
      }
    };
  } else if (provider === "stripe+paypro") {
    newPlan.providers = {
      stripe: newPlan.providers.stripe || {
        price: undefined,
        coupon: undefined
      },
      paypro: newPlan.providers.paypro || {
        product: undefined,
        "coupon-code-to-add": "",
        "page-template": 0
      }
    };
  }

  return newPlan;
};

const PlansBlock: React.FC<PlansBlockProps> = ({
  parameters,
  onChange,
  projectId
}) => {
  const handlePlanChange = (
    planIndex: number,
    field: string,
    value: unknown
  ) => {
    const updatedPlans = [...parameters.plans];
    const plan = { ...updatedPlans[planIndex] };

    // Special handling for switching to phases mode
    if (field === "providers.stripe.usePhases") {
      if (value === true) {
        // Initialize phases structure when enabling phases
        plan.providers.stripe = {
          ...plan.providers.stripe,
          phases: [
            {
              iterations: 1,
              items: [{ quantity: 1 }]
            },
            {
              items: [{ quantity: 1 }]
            }
          ]
        };
      } else {
        // Reset to single price/coupon when disabling phases
        plan.providers.stripe = {
          ...plan.providers.stripe,
          price: undefined,
          coupon: undefined
        };
      }
      updatedPlans[planIndex] = plan;
      onChange("plans", updatedPlans);
      return;
    }

    // Handle nested fields like "providers.stripe.price"
    const fieldParts = field.split(".");
    let current: Record<string, unknown> = plan;

    // Special handling for stripe coupon when it's undefined
    if (field === "providers.stripe.coupon" && value === undefined) {
      if (plan.providers?.stripe) {
        delete plan.providers.stripe.coupon;
      }
    } else {
      // Regular field handling
      for (let i = 0; i < fieldParts.length - 1; i++) {
        if (!current[fieldParts[i]]) {
          current[fieldParts[i]] = {};
        }
        current = current[fieldParts[i]] as Record<string, unknown>;
      }
      current[fieldParts[fieldParts.length - 1]] = value;
    }

    // Sanitize the providers configuration after any change
    updatedPlans[planIndex] = sanitizeProviders(
      plan as Plan,
      parameters.provider
    );
    onChange("plans", updatedPlans);

    console.log(updatedPlans);
  };

  const handleAddPlan = () => {
    const basePlan: Plan = {
      title: "",
      billingPeriod: "",
      highlighted: false,
      highlighted_text: "",
      perday_text: "",
      productName: "",
      predicted_ltv: 0,
      recurringPriceCrossedOut: false,
      showDiscountBadge: false,
      soldout: false,
      noteText: "",
      paymentFormNoteText: "",
      providers: {},
      url: { name: "", description: "" }
    };

    const newPlan = sanitizeProviders(basePlan, parameters.provider);
    onChange("plans", [...parameters.plans, newPlan]);
  };

  const handleRemovePlan = (planIndex: number) => {
    const updatedPlans = parameters.plans.filter(
      (_, index) => index !== planIndex
    );
    onChange("plans", updatedPlans);
  };

  const handleProviderChange = (
    newProvider: "stripe" | "paypro" | "stripe+paypro"
  ) => {
    const updatedPlans = parameters.plans.map((plan) =>
      sanitizeProviders(plan, newProvider)
    );
    onChange("provider", newProvider);
    onChange("plans", updatedPlans);
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <ColorChooser
          label="Background Color"
          value={parameters.backgroundColor}
          onChange={(color) => onChange("backgroundColor", color)}
        />
        <TextField
          label="Index"
          type="number"
          value={parameters.index}
          onChange={(e) => onChange("index", Number(e.target.value))}
        />
        <TextField
          label="Title"
          value={parameters.title}
          onChange={(e) => onChange("title", e.target.value)}
        />
        <TextField
          label="CTA Button Text"
          value={parameters.ctaButtonText}
          onChange={(e) => onChange("ctaButtonText", e.target.value)}
        />
        <TextField
          label="Agreement Message"
          value={parameters.agreementMessage}
          onChange={(e) => onChange("agreementMessage", e.target.value)}
        />
        <Typography variant="subtitle1" sx={{ mb: -1 }}>
          Provider
        </Typography>
        <ProviderSelect
          value={parameters.provider}
          onChange={(value) => handleProviderChange(value)}
          sx={{
            width: "100%"
          }}
        />
        {parameters.image !== undefined && (
          <ImageUpload
            projectId={projectId}
            label="Image"
            value={parameters.image}
            onChange={(url) => onChange("image", url)}
          />
        )}
        <TextField
          label="Secured Note Text"
          value={parameters.securedNoteText}
          onChange={(e) => onChange("securedNoteText", e.target.value)}
        />
        <Box
          sx={{
            marginTop: 2,
            padding: 2,
            border: "1px solid #ccc",
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            gap: 2
          }}>
          <Typography variant="h6">Remarket Banner</Typography>
          <ImageUpload
            projectId={projectId}
            key="remarket-banner-image"
            label="Banner Image"
            value={parameters.remarket.banner.image}
            onChange={(url) =>
              onChange("remarket", {
                ...parameters.remarket,
                banner: {
                  ...parameters.remarket.banner,
                  image: url
                }
              })
            }
          />
          <TextField
            label="Banner Title"
            value={parameters.remarket.banner.title}
            onChange={(e) =>
              onChange("remarket", {
                ...parameters.remarket,
                banner: {
                  ...parameters.remarket.banner,
                  title: e.target.value
                }
              })
            }
          />
          <TextField
            label="Button Text"
            value={parameters.remarket.banner.buttonText}
            onChange={(e) =>
              onChange("remarket", {
                ...parameters.remarket,
                banner: {
                  ...parameters.remarket.banner,
                  buttonText: e.target.value
                }
              })
            }
          />
          <TextField
            label="Upper Text"
            value={parameters.remarket.banner.upperText}
            onChange={(e) =>
              onChange("remarket", {
                ...parameters.remarket,
                banner: {
                  ...parameters.remarket.banner,
                  upperText: e.target.value
                }
              })
            }
          />
          <TextField
            label="Lower Text"
            value={parameters.remarket.banner.lowerText}
            onChange={(e) =>
              onChange("remarket", {
                ...parameters.remarket,
                banner: {
                  ...parameters.remarket.banner,
                  lowerText: e.target.value
                }
              })
            }
          />
        </Box>
        <Box
          sx={{
            marginTop: 2,
            padding: 2,
            border: "1px solid #ccc",
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            gap: 2
          }}>
          <Typography variant="h6">Goals</Typography>
          {parameters.goals.map((goal, goalIndex) => (
            <Box
              key={goalIndex}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <TextField
                label="Goal Text"
                value={goal.text}
                onChange={(e) => {
                  const updatedGoals = [...parameters.goals];
                  updatedGoals[goalIndex].text = e.target.value;
                  onChange("goals", updatedGoals);
                }}
              />
              <TextField
                label="Emoji"
                value={goal.emoji}
                onChange={(e) => {
                  const updatedGoals = [...parameters.goals];
                  updatedGoals[goalIndex].emoji = e.target.value;
                  onChange("goals", updatedGoals);
                }}
              />
            </Box>
          ))}
          <Button
            variant="outlined"
            onClick={() => {
              const updatedGoals = [
                ...parameters.goals,
                { text: "", emoji: "" }
              ];
              onChange("goals", updatedGoals);
            }}>
            Add Goal
          </Button>
        </Box>
        {parameters.plans.map((plan, planIndex) => (
          <Box
            key={planIndex}
            sx={{
              marginTop: 2,
              padding: 2,
              border: "1px solid #ccc",
              borderRadius: 1,
              display: "flex",
              flexDirection: "column",
              gap: 2
            }}>
            <Typography variant="h6">Plan {planIndex + 1}</Typography>
            <TextField
              label="Title"
              value={plan.title}
              onChange={(e) =>
                handlePlanChange(planIndex, "title", e.target.value)
              }
            />
            <TextField
              label="Billing Period"
              value={plan.billingPeriod}
              onChange={(e) =>
                handlePlanChange(planIndex, "billingPeriod", e.target.value)
              }
            />
            <TextField
              label="Product Name"
              value={plan.productName}
              onChange={(e) =>
                handlePlanChange(planIndex, "productName", e.target.value)
              }
            />
            <TextField
              label="Predicted LTV (USD, optional)"
              value={plan.predicted_ltv}
              onChange={(e) =>
                handlePlanChange(planIndex, "predicted_ltv", e.target.value)
              }
            />
            <TextField
              label="Note Text"
              value={plan.noteText}
              onChange={(e) =>
                handlePlanChange(planIndex, "noteText", e.target.value)
              }
            />
            <TextField
              label="Payment Form Note Text"
              value={plan.paymentFormNoteText}
              onChange={(e) =>
                handlePlanChange(
                  planIndex,
                  "paymentFormNoteText",
                  e.target.value
                )
              }
            />
            <FormControlLabel
              control={
                <Switch
                  checked={plan.highlighted}
                  onChange={(e) =>
                    handlePlanChange(planIndex, "highlighted", e.target.checked)
                  }
                />
              }
              label="Highlighted"
            />
            <TextField
              label="Highlighted Text"
              value={plan.highlighted_text}
              onChange={(e) =>
                handlePlanChange(planIndex, "highlighted_text", e.target.value)
              }
            />
            <FormControlLabel
              control={
                <Switch
                  checked={plan.recurringPriceCrossedOut}
                  onChange={(e) =>
                    handlePlanChange(
                      planIndex,
                      "recurringPriceCrossedOut",
                      e.target.checked
                    )
                  }
                />
              }
              label="Recurring Price Crossed Out"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={plan.showDiscountBadge}
                  onChange={(e) =>
                    handlePlanChange(
                      planIndex,
                      "showDiscountBadge",
                      e.target.checked
                    )
                  }
                />
              }
              label="Show Discount Badge"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={plan.soldout}
                  onChange={(e) =>
                    handlePlanChange(planIndex, "soldout", e.target.checked)
                  }
                />
              }
              label="Sold Out"
            />
            <TextField
              label="Per Day Text"
              value={plan.perday_text}
              onChange={(e) =>
                handlePlanChange(planIndex, "perday_text", e.target.value)
              }
            />
            <TextField
              label="URL Name (plan title)"
              value={plan.url.name}
              onChange={(e) =>
                handlePlanChange(planIndex, "url.name", e.target.value)
              }
            />
            <TextField
              label="URL Description"
              value={plan.url.description}
              onChange={(e) =>
                handlePlanChange(planIndex, "url.description", e.target.value)
              }
            />
            {(parameters.provider === "stripe" ||
              parameters.provider === "stripe+paypro") && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  borderTop: "1px solid #ccc",
                  borderBottom: "1px solid #ccc",
                  padding: "10px 15px",
                  backgroundColor: "#f0f0f0",
                  marginLeft: "-15px",
                  marginRight: "-15px"
                }}>
                <img src={stripeLogo} alt="Stripe" width={100} />
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!plan.providers.stripe?.phases}
                      onChange={(e) => {
                        if (e.target.checked) {
                          // Enable phases
                          handlePlanChange(planIndex, "providers.stripe", {
                            phases: [
                              {
                                iterations: 1,
                                items: [
                                  {
                                    quantity: 1,
                                    price: undefined,
                                    coupon: undefined
                                  }
                                ]
                              },
                              {
                                items: [
                                  {
                                    quantity: 1,
                                    price: undefined
                                  }
                                ]
                              }
                            ]
                          });
                        } else {
                          // Disable phases
                          handlePlanChange(planIndex, "providers.stripe", {
                            price: undefined,
                            coupon: undefined
                          });
                        }
                      }}
                    />
                  }
                  label="Use Subscription Phases"
                />

                {!plan.providers.stripe?.phases ? (
                  // Single price/coupon configuration
                  <>
                    <StripePriceDropdown
                      value={plan.providers.stripe?.price?.id || ""}
                      onChange={(price?: StripePrice) =>
                        handlePlanChange(
                          planIndex,
                          "providers.stripe.price",
                          price
                        )
                      }
                      projectId={projectId}
                    />
                    <StripeCouponDropdown
                      value={plan.providers.stripe?.coupon?.id || ""}
                      onChange={(coupon?: StripeCoupon) =>
                        handlePlanChange(
                          planIndex,
                          "providers.stripe.coupon",
                          coupon
                        )
                      }
                      projectId={projectId}
                    />
                  </>
                ) : (
                  // Phases configuration
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    {plan.providers.stripe?.phases?.map((phase, phaseIndex) => (
                      <Box
                        key={phaseIndex}
                        sx={{
                          border: "1px solid #ddd",
                          padding: 2,
                          borderRadius: 1
                        }}>
                        <Typography variant="subtitle1">
                          Phase {phaseIndex + 1}
                        </Typography>
                        {phaseIndex === 0 && (
                          <TextField
                            label="Iterations"
                            type="number"
                            value={phase.iterations || 1}
                            onChange={(e) =>
                              handlePlanChange(
                                planIndex,
                                `providers.stripe.phases.${phaseIndex}.iterations`,
                                Number(e.target.value)
                              )
                            }
                          />
                        )}
                        {phase.items.map((item, itemIndex) => (
                          <Box key={itemIndex} sx={{ mt: 2 }}>
                            <StripePriceDropdown
                              value={item.price?.id || ""}
                              onChange={(price?: StripePrice) =>
                                handlePlanChange(
                                  planIndex,
                                  `providers.stripe.phases.${phaseIndex}.items.${itemIndex}.price`,
                                  price
                                )
                              }
                              projectId={projectId}
                            />
                            {phaseIndex === 0 && (
                              <StripeCouponDropdown
                                value={item.coupon?.id || ""}
                                onChange={(coupon?: StripeCoupon) =>
                                  handlePlanChange(
                                    planIndex,
                                    `providers.stripe.phases.${phaseIndex}.items.${itemIndex}.coupon`,
                                    coupon
                                  )
                                }
                                projectId={projectId}
                              />
                            )}
                          </Box>
                        ))}
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            )}
            {(parameters.provider === "paypro" ||
              parameters.provider === "stripe+paypro") && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  borderTop: "1px solid #ccc",
                  borderBottom: "1px solid #ccc",
                  padding: "10px 15px",
                  backgroundColor: "#f0f0f0",
                  marginLeft: "-15px",
                  marginRight: "-15px"
                }}>
                <img src={payproLogo} alt="PayPro" width={100} />
                <PayProProductDropdown
                  value={plan.providers.paypro?.product?.id || 0}
                  onChange={(product?: PayProProduct) =>
                    handlePlanChange(
                      planIndex,
                      "providers.paypro.product",
                      product
                    )
                  }
                  projectId={projectId}
                />
                <TextField
                  label="PayPro Coupon Code"
                  value={plan.providers.paypro?.["coupon-code-to-add"] || ""}
                  onChange={(e) =>
                    handlePlanChange(
                      planIndex,
                      "providers.paypro.coupon-code-to-add",
                      e.target.value
                    )
                  }
                />
                <TextField
                  label="PayPro Page Template"
                  type="number"
                  value={plan.providers.paypro?.["page-template"] || ""}
                  onChange={(e) =>
                    handlePlanChange(
                      planIndex,
                      "providers.paypro.page-template",
                      Number(e.target.value)
                    )
                  }
                />
              </Box>
            )}

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleRemovePlan(planIndex)}>
              Remove Plan
            </Button>
          </Box>
        ))}
        <Button variant="contained" color="primary" onClick={handleAddPlan}>
          Add New Plan
        </Button>
      </Box>
    </Paper>
  );
};

export default PlansBlock;

import React from "react";
import { Button, Typography, Box, Paper } from "@mui/material";
import Goals from "./Goals";

interface Plan {
  title: string;
  billingPeriod: string;
  highlighted: boolean;
  productName: string;
  predicted_ltv: number;
  recurringPriceCrossedOut: boolean;
  showDiscountBadge: boolean;
  soldout: boolean;
  noteText: string;
  paymentFormNoteText: string;
  providers: {
    stripe?: {
      stripe: string;
      coupon: string;
    };
    paypro?: {
      paypro: number;
      "coupon-code-to-add": string;
      "page-template": number;
    };
  };
  url: {
    name: string;
    description: string;
  };
  initialPrice: string;
  recurringPrice: string;
  pricePerDay?: string;
  ctaButtonText?: string;
}

interface PlansProps {
  backgroundColor: string;
  goals: { text: string; emoji: string }[];
  image?: string;
  index: number;
  plans: Plan[];
  title: string;
  provider: "stripe" | "paypro" | "stripe+paypro";
  remarket: {
    banner: {
      image: string;
      title: string;
      buttonText: string;
      upperText: string;
      lowerText: string;
    };
  };
}

const PlanItem: React.FC<{ plan: Plan }> = ({ plan }) => (
  <Paper
    elevation={3}
    sx={{
      p: 2,
      m: 1,
      backgroundColor: plan.highlighted ? "#f0f0f0" : "white"
    }}>
    <Typography variant="h6">{plan.title}</Typography>
    <Typography>{plan.billingPeriod}</Typography>
    <Typography variant="h5">
      ${plan.initialPrice}
      {plan.recurringPriceCrossedOut && (
        <span style={{ textDecoration: "line-through", marginLeft: "10px" }}>
          ${plan.recurringPrice}
        </span>
      )}
    </Typography>
    {plan.pricePerDay && <Typography>Just ${plan.pricePerDay}/day</Typography>}
    {plan.showDiscountBadge && (
      <Box sx={{ bgcolor: "primary.main", color: "white", p: 1, mt: 1 }}>
        DISCOUNT
      </Box>
    )}
    <Button
      variant="contained"
      color="primary"
      sx={{ mt: 2 }}
      disabled={plan.soldout}>
      {plan.ctaButtonText || "Select Plan"}
    </Button>
    {plan.soldout && <Typography color="error">Sold Out</Typography>}
    <Typography variant="body2" sx={{ mt: 1 }}>
      {plan.noteText}
    </Typography>
  </Paper>
);

const Plans: React.FC<PlansProps> = ({
  backgroundColor,
  goals,
  image,
  plans,
  title,
  remarket
}) => {
  return (
    <Box sx={{ backgroundColor, p: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        {title}
      </Typography>

      {goals && goals.length > 0 && <Goals goals={goals} />}

      {image && (
        <Box
          component="img"
          src={image}
          alt="Plans image"
          sx={{ width: "100%", mb: 2 }}
        />
      )}

      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {plans.map((plan, i) => (
          <PlanItem key={i} plan={plan} />
        ))}
      </Box>

      {remarket.banner && (
        <Paper sx={{ mt: 3, p: 2, backgroundColor: "#f9f9f9" }}>
          <Typography variant="h6">{remarket.banner.title}</Typography>
          <Typography>{remarket.banner.upperText}</Typography>
          <Typography>{remarket.banner.lowerText}</Typography>
          <Button variant="outlined" sx={{ mt: 1 }}>
            {remarket.banner.buttonText}
          </Button>
        </Paper>
      )}
    </Box>
  );
};

export default Plans;

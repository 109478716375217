import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";

export interface StripeCoupon {
  id: string;
  name: string;
  amount_off?: number;
  percent_off?: number;
  duration: string;
}

interface StripeCouponDropdownProps {
  value: string;
  onChange: (coupon: StripeCoupon | undefined) => void;
  projectId: string;
  label?: string;
}

const StripeCouponDropdown: React.FC<StripeCouponDropdownProps> = ({
  value,
  onChange,
  projectId,
  label = "Select Coupon",
}) => {
  const [coupons, setCoupons] = useState<StripeCoupon[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const functions = getFunctions();
        const listStripeCoupons = httpsCallable(functions, "listStripeCoupons");
        const result = await listStripeCoupons({ projectId });
        setCoupons(result.data as StripeCoupon[]);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "Failed to fetch coupons"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCoupons();
  }, [projectId]);

  const formatCouponLabel = (coupon: StripeCoupon) => {
    const discount = coupon.percent_off
      ? `${coupon.percent_off}% off`
      : coupon.amount_off
      ? `$${(coupon.amount_off / 100).toFixed(2)} off (${coupon.duration})`
      : "Special discount";

    return `${discount}`;
  };

  if (loading) return <TextField label="Loading coupons..." disabled />;
  if (error)
    return (
      <TextField
        label="Error loading coupons"
        error
        helperText={error}
        disabled
      />
    );

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={(e) => onChange(coupons.find((c) => c.id === e.target.value))}
        label={label}
        sx={{ height: 48 }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {coupons.map((coupon) => (
          <MenuItem key={coupon.id} value={coupon.id}>
            {formatCouponLabel(coupon)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StripeCouponDropdown;

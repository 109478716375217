import React, { useState, useId, useEffect, useCallback } from "react";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";

interface ImageUploadProps {
  label: string;
  value: string;
  onChange: (url: string) => void;
  projectId?: string;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  label,
  value,
  onChange,
  projectId,
}) => {
  const [imageLoading, setImageLoading] = useState(false);
  const uploadId = useId();
  const [user, setUser] = useState<User>();
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user != null && projectId) {
        setUser(user);
      }
    });
  }, [projectId]);

  const handleUploadClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const storage = getStorage();
      const file = event.target.files?.[0];

      if (!file || !user) return;

      const storagePath = `${user.uid}/${projectId}/images/${file.name}`;

      const storageRef = ref(storage, storagePath);
      const uploadTask = uploadBytesResumable(storageRef, file);
      setImageLoading(true);

      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          alert(error);
          setImageLoading(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            onChange(downloadURL);
            setImageLoading(false);
          });
        }
      );
    },
    [user, projectId, onChange]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <TextField label={label} value={value} disabled />
      <label htmlFor={uploadId}>
        <Button variant="contained" component="span" sx={{ height: "20px" }}>
          {imageLoading ? (
            <CircularProgress size={12} sx={{ color: "#fff" }} />
          ) : (
            "Select Image"
          )}
          <input
            accept="image/*"
            id={uploadId}
            style={{ display: "none" }}
            type="file"
            onChange={handleUploadClick}
          />
        </Button>
      </label>
      {value && (
        <img
          src={value}
          alt="Uploaded"
          style={{ width: "100px", borderRadius: "8px" }}
        />
      )}
    </Box>
  );
};

export default ImageUpload;

import { parseVariables } from "../../../../utils/parseStrings";
import { ScaleField } from "./ScaleField";
import { ScaleNotation } from "../../../../utils/useScaleNotationValue";
import { Box, Button, Typography } from "@mui/material";
import { Progress } from "../../../QuizLayout/components/Progress";
import Highlight from "../../../Highlight";
import { useEffect, useState } from "react";

interface ComponentProps {
  title?: string;
  subtitle?: string;
  placeholder?: string;
  options?: { text: string; image: string }[];
  cta?: string;
  screenName?: string;
  hint_value?: string;
  hint_novalue?: string;
}

const Component = (props: ComponentProps) => {
  const { title, screenName, cta, hint_value = "", hint_novalue = "" } = props;
  console.log("ScreenName", screenName);
  const value = 0;
  const [hintParsed, setHintParsed] = useState("");

  useEffect(() => {
    setHintParsed(hint_value);
  }, [hint_value]);

  useEffect(() => {
    setHintParsed(hint_novalue);
  }, [hint_novalue]);

  return (
    <div id="inputContainer">
      <Box sx={{ marginBottom: "17px" }} />
      <Progress />
      <Box sx={{ marginBottom: "24px" }} />
      {title && (
        <Typography
          variant="onboarding_title"
          align="center"
          dangerouslySetInnerHTML={{ __html: parseVariables(title) }}
          sx={{ marginBottom: "12px" }}></Typography>
      )}
      {/* {subtitle && (
    <Typography
      // variant="onboarding_subtitle"
      align="center"
      sx={{ marginBottom: "36px" }}
      className={s.subtitle}
    >
      {subtitle}
    </Typography>
  )} */}
      <Box>
        <ScaleField
          scales={[
            { value: ScaleNotation.imperial, label: "Ft" },
            { value: ScaleNotation.metric, label: "Cm" }
          ]}
          value={value || 0}
          scaleNotation={ScaleNotation.imperial}
        />
      </Box>

      {/* {highlight && ( */}
      <Highlight>
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: parseVariables(hintParsed)
            }}></span>
        </>
      </Highlight>

      <Button
        fullWidth
        variant="cta"
        id="nextStepButton"
        sx={{ marginTop: "24px" }}>
        {cta}
      </Button>
      {/* {helperText ? helperText : null} */}
    </div>
  );
};

export default Component;

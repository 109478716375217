import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";

interface TimerProps {
  backgroundColor: string;
  time: number;
  text: string;
  ctaButtonText: string;
}

const Timer: React.FC<TimerProps> = ({
  backgroundColor,
  time,
  text,
  ctaButtonText,
}) => {
  const [timeLeft, setTimeLeft] = useState(time);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <div
      style={{
        backgroundColor,
        padding: "20px",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <p style={{ fontSize: "18px", margin: 0 }}>{text}</p>
      <div
        style={{
          display: "flex",
          gap: "10px",
          fontSize: "24px",
          fontWeight: "bold",
        }}
      >
        <span>{minutes.toString().padStart(2, "0")}</span>
        <span>:</span>
        <span>{seconds.toString().padStart(2, "0")}</span>
      </div>
      <Button variant="contained" color="primary" style={{ marginTop: "10px" }}>
        {ctaButtonText}
      </Button>
    </div>
  );
};

export default Timer;

import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
} from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";

export interface StripePrice {
  id: string;
  nickname: string;
  unit_amount: number;
  currency: string;
  recurring: {
    interval: string;
    interval_count: number;
  };
  product: {
    name: string;
    images?: string[];
  };
}

interface StripePriceDropdownProps {
  value: string;
  onChange: (value?: StripePrice) => void;
  projectId: string;
}

const StripePriceDropdown: React.FC<StripePriceDropdownProps> = ({
  value,
  onChange,
  projectId,
}) => {
  const [prices, setPrices] = useState<StripePrice[]>([]);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const functions = getFunctions();
        const getStripePrices = httpsCallable(functions, "getStripePrices");
        const result = await getStripePrices({ projectId });
        const data = result.data as { prices: StripePrice[] };
        setPrices(data.prices);
      } catch (error) {
        console.error("Error fetching Stripe prices:", error);
      }
    };

    fetchPrices();
  }, [projectId]);

  const renderPriceInfo = (price: StripePrice) => (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      {price.product.images?.[0] && (
        <img
          src={price.product.images[0]}
          alt={price.product.name}
          style={{
            width: 30,
            height: 30,
            objectFit: "contain",
          }}
        />
      )}
      <Box>
        <Typography variant="body1">{price.product.name}</Typography>
        <Typography variant="caption" color="text.secondary">
          {price.unit_amount / 100} {price.currency.toUpperCase()}
          {price.recurring &&
            ` / ${price.recurring.interval_count} ${price.recurring.interval}`}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <FormControl fullWidth>
      <InputLabel>Price</InputLabel>
      <Select
        value={value}
        onChange={(e) => onChange(prices.find((p) => p.id === e.target.value))}
        sx={{
          height: 56,
          "& .MuiSelect-select": {
            paddingY: 1,
          },
        }}
        renderValue={(selected) => {
          const selectedPrice = prices.find((p) => p.id === selected);
          if (!selectedPrice) return "";
          return renderPriceInfo(selectedPrice);
        }}
      >
        {prices.map((price) => (
          <MenuItem
            key={price.id}
            value={price.id}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              height: "auto",
              paddingY: 1,
            }}
          >
            {renderPriceInfo(price)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StripePriceDropdown;

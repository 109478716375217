import React from "react";
import { Box, TextField, Paper } from "@mui/material";
import ColorChooser from "../../../components/ColorChooser";

interface TimerBlockProps {
  parameters: {
    backgroundColor: string;
    ctaButtonText: string;
    text: string;
    time: number;
  };
  onChange: (param: string, value: any) => void;
}

const TimerBlock: React.FC<TimerBlockProps> = ({ parameters, onChange }) => {
  return (
    <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <ColorChooser
          label="Background Color"
          value={parameters.backgroundColor}
          onChange={(color) => onChange("backgroundColor", color)}
        />
        <TextField
          label="CTA Button Text"
          value={parameters.ctaButtonText}
          onChange={(e) => onChange("ctaButtonText", e.target.value)}
        />
        <TextField
          label="Text"
          value={parameters.text}
          onChange={(e) => onChange("text", e.target.value)}
        />
        <TextField
          label="Time"
          type="number"
          value={parameters.time}
          onChange={(e) => onChange("time", Number(e.target.value))}
        />
      </Box>
    </Paper>
  );
};

export default TimerBlock;

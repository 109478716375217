import React, { useEffect, useState } from "react";
import {
  MenuItem,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Box,
  Typography,
} from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";

export interface PayproPricing {
  productId: number;
  quantity: number;
  selections: unknown[];
  name: string;
  sku: string | null;
  logoUrl: string;
  appliedCouponCode: string | null;
  billingDiscount: number;
  billingPrice: number;
  billingPriceBeforeDiscount: number;
  billingPriceWithTaxes: number;
  billingTaxes: number;
  billingUnitPrice: number;
  displayDiscount: number;
  displayPrice: number;
  displayPriceBeforeDiscount: number;
  displayPriceWithTaxes: number;
  displayTaxes: number;
  displayUnitPrice: number;
  recurring: PayproPricing;
}

export interface PayProProduct {
  id: number;
  name: string;
  description: string;
  sku: string;
  pricing?: PayproPricing;
}

interface PayProProductDropdownProps {
  value: number;
  onChange: (product?: PayProProduct) => void;
  projectId: string;
}

const PayProProductDropdown: React.FC<PayProProductDropdownProps> = ({
  value,
  onChange,
  projectId,
}) => {
  const [products, setProducts] = useState<PayProProduct[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      setError(null);
      try {
        const functions = getFunctions();
        const getPayProProducts = httpsCallable(functions, "getPayProProducts");
        const result = await getPayProProducts({ projectId });
        const data = result.data as PayProProduct[];
        setProducts(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "Failed to fetch products"
        );
      } finally {
        setLoading(false);
      }
    };

    if (projectId) {
      fetchProducts();
    }
  }, [projectId]);

  const renderProductInfo = (product: PayProProduct) => (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      {product.pricing?.logoUrl && (
        <img
          src={product.pricing.logoUrl}
          alt={product.name}
          style={{
            width: 30,
            height: 30,
            objectFit: "contain",
          }}
        />
      )}
      <Box>
        <Typography variant="body1">
          {product.name} {product.sku ? `(${product.sku})` : ""}
        </Typography>
        {product.pricing && (
          <Typography variant="caption" color="text.secondary">
            {product.pricing.billingPrice}
            {product.pricing.recurring &&
            product.pricing.recurring.billingPrice !==
              product.pricing.billingPrice
              ? ` -> ${product.pricing.recurring.billingPrice}`
              : ""}
          </Typography>
        )}
      </Box>
    </Box>
  );

  if (loading) {
    return <CircularProgress size={24} />;
  }

  if (error) {
    return <TextField error fullWidth value="" helperText={error} disabled />;
  }

  return (
    <FormControl fullWidth>
      <InputLabel>PayPro Product</InputLabel>
      <Select
        value={value}
        onChange={(e) =>
          onChange(products.find((p) => p.id === Number(e.target.value)))
        }
        sx={{
          height: 56,
          "& .MuiSelect-select": {
            paddingY: 1,
          },
        }}
        renderValue={(selected) => {
          const selectedProduct = products.find(
            (p) => p.id === Number(selected)
          );
          if (!selectedProduct) return "";
          return renderProductInfo(selectedProduct);
        }}
      >
        <MenuItem value="">Select a product</MenuItem>
        {products.map((product) => (
          <MenuItem
            key={product.id}
            value={product.id}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              height: "auto",
              paddingY: 1,
            }}
          >
            {renderProductInfo(product)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PayProProductDropdown;

import React from "react";
import { Box, Typography } from "@mui/material";

interface GoalsProps {
  goals: { text: string; emoji: string }[];
}

const Goals: React.FC<GoalsProps> = ({ goals }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 2 }}>
      {goals.map((goal, index) => (
        <Box key={index} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="h6" component="span">
            {goal.emoji}
          </Typography>
          <Typography dangerouslySetInnerHTML={{ __html: goal.text }} />
        </Box>
      ))}
    </Box>
  );
};

export default Goals;

import PrognosisImageV2 from "./PrognosisImageV2/PrognosisImageV2";
import { parseVariables } from "../../../../utils/parseStrings";
import { Typography } from "@mui/material";
import ActionsContainer from "../../../ActionsContainer";
interface ComponentProps {
  title?: string;
  subtitle?: string;
  hint?: string;
  cta?: string;
  screenName?: string;
  threeMonths?: boolean;
  goal_label?: string;
}

const Component = (props: ComponentProps) => {
  const {
    title,
    subtitle,
    hint,
    screenName,
    threeMonths = false,
    cta,
    goal_label = "Goal"
  } = props;
  console.log("screenName", screenName);
  return (
    <>
      {title && (
        <Typography
          align="center"
          dangerouslySetInnerHTML={{
            __html: parseVariables(title)
          }}
          sx={{
            marginTop: "17px",
            marginBottom: "12px",
            fontFamily: "SF UI",
            fontSize: "16px",
            letterSpacing: "-.02em",
            lineHeight: "24px"
          }}></Typography>
      )}
      {subtitle && (
        <Typography
          dangerouslySetInnerHTML={{
            __html: parseVariables(subtitle)
          }}
          sx={{
            fontFamily: "SF UI",
            textAlign: "center",
            marginBottom: "33px",
            color: "#70C305",
            fontSize: "26px",
            fontWeight: "700",
            lineHeight: "31px"
          }}></Typography>
      )}

      <PrognosisImageV2 threeMonths={threeMonths} goal_label={goal_label} />

      <Typography
        align="center"
        sx={{
          fontFamily: "SF UI",
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "18px",
          color: "#a6a6a6",
          marginTop: "24px",
          letterSpacing: "-0.24px"
        }}>
        {parseVariables(hint) && (
          <span
            dangerouslySetInnerHTML={{ __html: parseVariables(hint) }}></span>
        )}
      </Typography>
      <ActionsContainer cta={cta} />
    </>
  );
};

export default Component;

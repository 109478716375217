import { Box, Button, Paper, Typography } from "@mui/material";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getFirestore, onSnapshot, updateDoc } from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Project } from "../../types";
import { useSnackbar } from "notistack";

interface ComponentProps {
  directory?: string;
}

const Component = (props: ComponentProps) => {
  const { directory = "/" } = props;
  console.log("Directory", directory);
  const params = useParams();
  const { projectId } = params;
  const notistack = useSnackbar();
  const [user, setUser] = useState<User>();
  const [project, setProject] = useState<Project>();
  const [loading, setLoading] = useState(false);
  console.log("Loading", loading);
  const syncProject = (user: User, projectId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const projectRef = doc(db, "projects", user.uid, "projects", projectId);
      onSnapshot(projectRef, (snapshot) => {
        const project = { id: snapshot.id, ...snapshot.data() } as Project;
        setProject(project);
        setLoading(false);
      });
    } catch (error) {
      setProject(undefined);
      setLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user != null && projectId) {
        setUser(user);
        syncProject(user, projectId);
      }
    });
  }, []);

  // const [imageProgressPercent, setImageProgressPercent] = useState(0);
  const handleUploadClick = useCallback(
    (event: any) => {
      const storage = getStorage();
      const file = event.target.files[0];
      console.log("Location1 ", directory);
      if (!file) return;
      console.log("Location2", directory);
      if (user && projectId) {
        const fileLocation = `${user.uid}/${projectId}/files${directory}${file.name}`;
        console.log("Location", fileLocation);
        const storageRef = ref(storage, fileLocation);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log("Upload inprogress", progress);
            // setImageProgressPercent(progress);
          },
          (error) => {
            alert(error);
          },
          () => {
            console.log("Upload finished");
            getDownloadURL(uploadTask.snapshot.ref).then(
              async (downloadURL) => {
                console.log("Url", downloadURL, project);
                const db = getFirestore();
                try {
                  setLoading(true);
                  await updateDoc(
                    doc(db, "projects", user.uid, "projects", projectId),
                    {
                      parameters: {
                        ...(project?.parameters || {}),
                        files: [
                          ...(project?.parameters?.files || []),
                          downloadURL,
                        ],
                      },
                    }
                  );
                  notistack.enqueueSnackbar("File uploaded!", {
                    variant: "success",
                  });
                  syncProject(user, projectId);
                } catch (error) {
                  console.error(error);
                  notistack.enqueueSnackbar("File upload failed!", {
                    variant: "error",
                  });
                } finally {
                  setLoading(false);
                }
                // setImageProgressPercent(0);
              }
            );
          }
        );
      }
    },
    [user, project, projectId, directory]
  );

  const normalizeName = useCallback(
    (name: string) => {
      return user
        ? name
            .split("?alt=media")[0]
            .replace(
              `https://firebasestorage.googleapis.com/v0/b/funnel7-prod.appspot.com/o/${user.uid}%2F${projectId}%2Ffiles`,
              ""
            )
        : "";
    },
    [user]
  );

  const filteredProjectFiles =
    project?.parameters?.files &&
    project?.parameters?.files
      .map((file: string) => normalizeName(file))
      .filter((file: any) => {
        return file.startsWith(encodeURIComponent(directory));
      })
      .map((file: any) =>
        decodeURIComponent(file.replace(encodeURIComponent(directory), ""))
      )
      .filter((file: string) => {
        return !file.includes("/");
      });

  return (
    <Box
      key={directory}
      sx={{
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "normal",
          color: "#666",
          marginBottom: "12px",
        }}
      >
        Hosting Files ({directory})
      </Typography>
      <Paper sx={{ marginBottom: "10px", padding: "10px", width: "100%" }}>
        {(!project?.parameters?.files ||
          project.parameters.files.length === 0) && (
          <Typography>No files</Typography>
        )}
        {project?.parameters?.files &&
          filteredProjectFiles.map((file: string) => (
            <Typography>{file}</Typography>
          ))}
      </Paper>
      <Box>
        <label htmlFor={"button-file-" + directory}>
          <Button variant="contained" component="span" sx={{ height: "20px" }}>
            Select File
            <input
              accept="file"
              id={"button-file-" + directory}
              style={{
                display: "none",
              }}
              multiple
              type="file"
              onChange={(event) => {
                handleUploadClick(event);
              }}
            />
          </Button>
        </label>
      </Box>
    </Box>
  );
};

export default Component;

import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { nanoid } from "nanoid";
import { TextField } from "@mui/material";

import { Paywall, Project } from "../../types";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Switch,
  FormControlLabel,
  TextareaAutosize
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";

import TimerBlock from "./blocks/TimerBlock";
import ImageBlock from "./blocks/ImageBlock";
import PlansBlock from "./blocks/PlansBlock";
import ReviewsBlock from "./blocks/Reviews";
import BenefitsBlock from "./blocks/Benefits";
import PaywallPreview from "../../components/PaywallPreview/PaywallPreview";
import StripePriceDropdown, {
  StripePrice
} from "../../components/StripePriceDropdown";
import StripeCouponDropdown, {
  StripeCoupon
} from "../../components/StripeCouponDropdown";

import ProviderSelect from "../../components/ProviderSelect/ProviderSelect";
import PayProProductDropdown, {
  PayProProduct
} from "../../components/PayProProductDropdown";

const blockTypes = [
  { type: "Timer", component: TimerBlock },
  { type: "Image", component: ImageBlock },
  { type: "Plans", component: PlansBlock },
  { type: "Reviews", component: ReviewsBlock },
  { type: "Benefits", component: BenefitsBlock }
];

interface BlockParameters {
  [key: string]: unknown;
}

interface Block {
  id: string;
  type: string;
  parameters: BlockParameters;
  component: React.ComponentType<any> | null;
}

interface UpsellConfig {
  enabled: boolean;
  provider: "stripe" | "paypro" | "stripe+paypro";
  stripe?: {
    price?: StripePrice;
    normalDiscount?: StripeCoupon;
    upsellDiscount?: StripeCoupon;
  };
  paypro?: {
    product?: PayProProduct;
    normalDiscount?: string;
    upsellDiscount?: string;
    pageTemplate?: string;
  };
}

const PaywallPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { projectId, paywallId } = params;
  const [user, setUser] = useState<User | null>(null);
  const [project, setProject] = useState<Project | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedBlockType, setSelectedBlockType] = useState("");
  const [normalBlocks, setNormalBlocks] = useState<Block[]>([]);
  const [remarketBlocks, setRemarketBlocks] = useState<Block[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [useOldConfig, setUseOldConfig] = useState(false);
  const [oldConfigJson, setOldConfigJson] = useState("");
  const [upsellConfig, setUpsellConfig] = useState<UpsellConfig>({
    enabled: false,
    provider: "stripe",
    stripe: {
      price: undefined,
      normalDiscount: undefined,
      upsellDiscount: undefined
    },
    paypro: {
      product: undefined,
      normalDiscount: "",
      upsellDiscount: "",
      pageTemplate: ""
    }
  });

  console.log("Project", project, loading);
  const syncPaywall = (user: User, projectId: string, paywallId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const paywallRef = doc(
        db,
        "projects",
        user.uid,
        "projects",
        projectId,
        "paywalls",
        paywallId
      );
      onSnapshot(paywallRef, (snapshot) => {
        const paywall = { id: snapshot.id, ...snapshot.data() };
        const { parameters } = paywall as Paywall;
        console.log("Paywall", paywall);
        initializeBlocks(parameters || {});
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const syncProject = (user: User, projectId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const projectRef = doc(db, "projects", user.uid, "projects", projectId);
      onSnapshot(projectRef, (snapshot) => {
        const project = { id: snapshot.id, ...snapshot.data() };
        setProject(project as Project);
        setLoading(false);
      });
    } catch (error) {
      setProject(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user != null && projectId && paywallId) {
        setUser(user);
        syncProject(user, projectId);
        syncPaywall(user, projectId, paywallId);
      }
    });
  }, [user]);

  const initializeBlocks = (parameters: {
    blocks?: {
      default?: Array<{
        id?: string;
        type: string;
        parameters: BlockParameters;
      }>;
      remarket?: Array<{
        id?: string;
        type: string;
        parameters: BlockParameters;
      }>;
    };
    upsell?: {
      enabled: boolean;
      provider: "stripe" | "paypro" | "stripe+paypro";
      stripe?: {
        price?: StripePrice;
        normalDiscount?: StripeCoupon;
        upsellDiscount?: StripeCoupon;
      };
      paypro?: {
        product?: PayProProduct;
        normalDiscount?: string;
        upsellDiscount?: string;
        pageTemplate?: string;
      };
    } | null;
  }) => {
    if (parameters?.blocks) {
      if (parameters.blocks.default) {
        const normalBlocks = parameters.blocks.default.map((block) => {
          const blockType = blockTypes.find((b) => b.type === block.type);
          return {
            id: block.id || nanoid(),
            type: block.type,
            parameters: block.parameters,
            component: blockType ? blockType.component : null
          };
        });
        setNormalBlocks(normalBlocks);
      }

      if (parameters.blocks.remarket) {
        const remarketBlocks = parameters.blocks.remarket.map((block) => {
          const blockType = blockTypes.find((b) => b.type === block.type);
          return {
            id: block.id || nanoid(),
            type: block.type,
            parameters: block.parameters,
            component: blockType ? blockType.component : null
          };
        });
        setRemarketBlocks(remarketBlocks);
      }
    }

    setUpsellConfig({
      enabled: parameters?.upsell ? true : false,
      provider: parameters?.upsell?.provider || "stripe",
      stripe: parameters?.upsell?.stripe || {
        price: undefined,
        normalDiscount: undefined,
        upsellDiscount: undefined
      },
      paypro: parameters?.upsell?.paypro || {
        product: undefined,
        normalDiscount: "",
        upsellDiscount: "",
        pageTemplate: ""
      }
    });
  };

  const handleAddBlock = () => {
    const block = blockTypes.find((b) => b.type === selectedBlockType);
    if (block) {
      const newBlock = {
        id: nanoid(),
        type: block.type,
        parameters: {},
        component: block.component
      };
      if (activeTab === 0) {
        setNormalBlocks([...normalBlocks, newBlock]);
      } else {
        setRemarketBlocks([...remarketBlocks, newBlock]);
      }
    }
  };

  const handleParameterChange = (
    index: number,
    param: string,
    value: unknown
  ) => {
    if (activeTab === 0) {
      const updatedBlocks = [...normalBlocks];
      updatedBlocks[index].parameters[param] = value;
      setNormalBlocks(updatedBlocks);
    } else {
      const updatedBlocks = [...remarketBlocks];
      updatedBlocks[index].parameters[param] = value;
      setRemarketBlocks(updatedBlocks);
    }
  };

  const handleSave = async () => {
    if (user && projectId && paywallId) {
      const db = getFirestore();
      try {
        setLoading(true);
        const output = {
          blocks: {
            default: normalBlocks.map(({ component, ...rest }) => rest),
            remarket: remarketBlocks.map(({ component, ...rest }) => rest)
          },
          upsell: upsellConfig.enabled
            ? {
                provider: upsellConfig.provider,
                ...(upsellConfig.provider.includes("stripe")
                  ? {
                      stripe: {
                        price: upsellConfig.stripe?.price,
                        normalDiscount: upsellConfig.stripe?.normalDiscount,
                        upsellDiscount: upsellConfig.stripe?.upsellDiscount
                      }
                    }
                  : {}),
                ...(upsellConfig.provider.includes("paypro")
                  ? {
                      paypro: {
                        product: upsellConfig.paypro?.product,
                        normalDiscount: upsellConfig.paypro?.normalDiscount,
                        upsellDiscount: upsellConfig.paypro?.upsellDiscount,
                        pageTemplate: upsellConfig.paypro?.pageTemplate
                      }
                    }
                  : {})
              }
            : null
        };

        await updateDoc(
          doc(
            db,
            "projects",
            user.uid,
            "projects",
            projectId,
            "paywalls",
            paywallId
          ),
          {
            parameters: output
          }
        );
        enqueueSnackbar("Paywall configuration saved!", { variant: "success" });
        console.log("Saved JSON Output:", JSON.stringify(output, null, 2));
      } catch (error) {
        console.error("Error saving paywall configuration:", error);
        enqueueSnackbar("Error saving paywall configuration", {
          variant: "error"
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const renderPreview = () => {
    const blocks = activeTab === 0 ? normalBlocks : remarketBlocks;
    return <PaywallPreview blocks={blocks} />;
  };

  const handleConfigToggle = () => {
    setUseOldConfig(!useOldConfig);
    if (!useOldConfig) {
      // Switching to old config, convert current blocks to JSON
      const currentConfig = {
        blocks: {
          default: normalBlocks.map(({ component, ...rest }) => rest),
          remarket: remarketBlocks.map(({ component, ...rest }) => rest)
        }
      };
      setOldConfigJson(JSON.stringify(currentConfig, null, 2));
    } else {
      // Switching to new config, parse JSON and update blocks
      try {
        const parsedConfig = JSON.parse(oldConfigJson);
        initializeBlocks(parsedConfig);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        enqueueSnackbar("Error parsing JSON configuration", {
          variant: "error"
        });
      }
    }
  };

  const handleOldConfigChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setOldConfigJson(event.target.value);
  };

  const handleProviderChange = (
    provider: "stripe" | "paypro" | "stripe+paypro"
  ) => {
    setUpsellConfig((prev) => ({
      ...prev,
      provider
    }));
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* Left side - Configuration */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          backgroundColor: "rgba(217, 217, 217, 0.25)",
          padding: "48px",
          flexGrow: "auto",
          minHeight: "calc(100vh - 90px)",
          overflowY: "auto"
        }}>
        <Typography variant="h1">
          <Box component="span" sx={{ cursor: "pointer" }}>
            <IconButton
              sx={{ marginLeft: "-40px" }}
              onClick={() => navigate("./../../")}>
              <ChevronLeft />
            </IconButton>
            Edit data
          </Box>
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={useOldConfig}
              onChange={handleConfigToggle}
              name="useOldConfig"
            />
          }
          label="Use Old JSON Configuration"
        />
        {useOldConfig ? (
          <TextareaAutosize
            minRows={10}
            value={oldConfigJson}
            onChange={handleOldConfigChange}
            style={{ width: "100%", marginTop: "20px" }}
          />
        ) : (
          <>
            <Tabs
              value={activeTab}
              onChange={(_, newValue) => setActiveTab(newValue)}>
              <Tab label="Normal Paywall" />
              <Tab label="Remarket Paywall" />
              <Tab label="Upsell Configuration" />
            </Tabs>
            <Box sx={{ marginTop: "24px" }}>
              {activeTab === 2 ? (
                // Upsell Configuration Tab Content
                <Box>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={upsellConfig.enabled}
                        onChange={(e) =>
                          setUpsellConfig((prev) => ({
                            ...prev,
                            enabled: e.target.checked
                          }))
                        }
                      />
                    }
                    label="Enable Upsell"
                  />

                  {upsellConfig.enabled && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Provider
                      </Typography>
                      <ProviderSelect
                        value={upsellConfig.provider}
                        onChange={handleProviderChange}
                        sx={{ width: "100%" }}
                      />

                      {(upsellConfig.provider === "stripe" ||
                        upsellConfig.provider === "stripe+paypro") && (
                        <Box sx={{ mb: 3 }}>
                          <Typography variant="subtitle1" sx={{ mb: 1 }}>
                            Stripe Configuration
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 2
                            }}>
                            <StripePriceDropdown
                              value={upsellConfig.stripe?.price?.id || ""}
                              onChange={(price) =>
                                setUpsellConfig((prev) => ({
                                  ...prev,
                                  stripe: {
                                    ...prev.stripe!,
                                    price: price
                                  }
                                }))
                              }
                              projectId={projectId || ""}
                            />
                            <StripeCouponDropdown
                              label="Coupon Default"
                              value={
                                upsellConfig.stripe?.normalDiscount?.id || ""
                              }
                              onChange={(coupon) =>
                                setUpsellConfig((prev) => ({
                                  ...prev,
                                  stripe: {
                                    ...prev.stripe!,
                                    normalDiscount: coupon
                                  }
                                }))
                              }
                              projectId={projectId || ""}
                            />
                            <StripeCouponDropdown
                              label="Coupon Remarket"
                              value={
                                upsellConfig.stripe?.upsellDiscount?.id || ""
                              }
                              onChange={(coupon) =>
                                setUpsellConfig((prev) => ({
                                  ...prev,
                                  stripe: {
                                    ...prev.stripe!,
                                    upsellDiscount: coupon
                                  }
                                }))
                              }
                              projectId={projectId || ""}
                            />
                          </Box>
                        </Box>
                      )}

                      {(upsellConfig.provider === "paypro" ||
                        upsellConfig.provider === "stripe+paypro") && (
                        <Box sx={{ mb: 3 }}>
                          <Typography variant="subtitle1" sx={{ mb: 1 }}>
                            PayPro Configuration
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 2
                            }}>
                            <PayProProductDropdown
                              value={upsellConfig.paypro?.product?.id || 0}
                              onChange={(product) =>
                                setUpsellConfig((prev) => ({
                                  ...prev,
                                  paypro: {
                                    ...prev.paypro!,
                                    product: product
                                  }
                                }))
                              }
                              projectId={projectId || ""}
                            />
                            <TextField
                              label="Page Template"
                              value={upsellConfig.paypro?.pageTemplate || ""}
                              onChange={(e) =>
                                setUpsellConfig((prev) => ({
                                  ...prev,
                                  paypro: {
                                    ...prev.paypro!,
                                    pageTemplate: e.target.value
                                  }
                                }))
                              }
                            />
                            <TextField
                              label="Coupon Default"
                              value={upsellConfig.paypro?.normalDiscount || ""}
                              onChange={(e) =>
                                setUpsellConfig((prev) => ({
                                  ...prev,
                                  paypro: {
                                    ...prev.paypro!,
                                    normalDiscount: e.target.value
                                  }
                                }))
                              }
                            />
                            <TextField
                              label="Coupon Remarket"
                              value={upsellConfig.paypro?.upsellDiscount || ""}
                              onChange={(e) =>
                                setUpsellConfig((prev) => ({
                                  ...prev,
                                  paypro: {
                                    ...prev.paypro!,
                                    upsellDiscount: e.target.value
                                  }
                                }))
                              }
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              ) : (
                // Normal and Remarket Paywall Tab Content
                <>
                  <Select
                    value={selectedBlockType}
                    onChange={(e) =>
                      setSelectedBlockType(e.target.value as string)
                    }>
                    {blockTypes.map((block) => (
                      <MenuItem key={block.type} value={block.type}>
                        {block.type}
                      </MenuItem>
                    ))}
                  </Select>
                  <Button onClick={handleAddBlock}>Add Block</Button>

                  {(activeTab === 0 ? normalBlocks : remarketBlocks).map(
                    (block, index) => {
                      const BlockComponent = block.component;
                      return (
                        <Box key={block.id} sx={{ marginTop: "16px" }}>
                          <Typography variant="h6">{block.type}</Typography>
                          {BlockComponent && (
                            <BlockComponent
                              parameters={{
                                ...block.parameters,
                                NEXT_PUBLIC_STRIPE_KEY:
                                  project?.parameters?.NEXT_PUBLIC_STRIPE_KEY
                              }}
                              projectId={projectId}
                              onChange={(param: string, value: unknown) =>
                                handleParameterChange(index, param, value)
                              }
                            />
                          )}
                        </Box>
                      );
                    }
                  )}
                </>
              )}
            </Box>
          </>
        )}
        <Button onClick={handleSave} disabled={loading}>
          {loading ? "Saving..." : "Save Configuration"}
        </Button>
      </Box>

      {/* Right side - Preview */}
      <Box
        sx={{
          display: "flex",
          flexGrow: "1",
          backgroundColor: "#fff",
          overflowY: "auto",
          justifyContent: "center",
          alignItems: "flex-start"
        }}>
        {renderPreview()}
      </Box>
    </Box>
  );
};

export default PaywallPage;

import { useState } from "react";

export type UseQuizOptionGroupResult = {
  selectedOption?: string | number;
  selectOption: (value: string) => void;
};

export const useQuizOptionGroup = (
  field: string,
  multiSelect?: boolean
): UseQuizOptionGroupResult => {
  console.log("Field", field);
  const [fieldValue, setFieldValue] = useState<string | undefined>();

  const handleSelectOption: UseQuizOptionGroupResult["selectOption"] = (
    value: string
  ) => {
    if (multiSelect) {
      let newValue = fieldValue;
      const values = fieldValue ? (fieldValue + "" || "").split(",") : [];
      if (values.indexOf(value) !== -1) {
        newValue = values.filter((v) => v !== value).join(",");
      } else {
        values.push(value);
        newValue = values.join(",");
      }
      console.log("Set multiselect value", newValue);
      if (newValue === "") {
        newValue = undefined;
      }
      setFieldValue(newValue);
    } else {
      setFieldValue(value);
    }
  };

  return { selectedOption: fieldValue, selectOption: handleSelectOption };
};

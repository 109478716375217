import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import "dayjs/locale/de"; // German
import "dayjs/locale/it"; // Italian
import "dayjs/locale/fr"; // French
import "dayjs/locale/es"; // Spanish

dayjs.extend(localeData);

// Set the locale to the browser's language or default to English
const supportedLocales = ["de", "it", "fr", "es", "en"];
const userLocale = supportedLocales.includes(navigator.language)
  ? navigator.language
  : "en";
dayjs.locale(userLocale);

import image from "./image.svg";
import s from "./PrognosisImageV2.module.css";

const DAILY_LOSS = 0.4 / 2.205; // 0.4 lbs per day to kg;

interface PrognosisImageV2 {
  threeMonths?: boolean;
  goal_label?: string;
}

const PrognosisImageV2 = (props: PrognosisImageV2) => {
  const { threeMonths, goal_label } = props;

  let prognosisWeights;
  let prognosisDates;
  let diff;

  if (threeMonths) {
    diff = 30;
  } else {
    diff = 10;
  }

  const daysToTarget = parseInt((diff / DAILY_LOSS).toFixed(0));
  const interval = daysToTarget / 4;
  const targetDate = dayjs().add(daysToTarget, "days").format("MMM DD");
  prognosisDates = [
    "",
    dayjs(targetDate)
      .subtract(interval * 3, "days")
      .format("MMM DD"),
    dayjs(targetDate)
      .subtract(interval * 2, "days")
      .format("MMM DD"),
    dayjs(targetDate).subtract(interval, "days").format("MMM DD"),
    targetDate
  ];

  prognosisWeights = Array.from({ length: 7 }, (_, index) => {
    const number = 85 - (index * 10) / 6;
    return parseInt((number * 2.205).toFixed(0));
  });

  return (
    <div className={s.container}>
      <div className={s.prognosisWeights}>
        {prognosisWeights.map((weight, index) => (
          <span key={index} className={s.weight}>
            {weight || ""}
          </span>
        ))}
      </div>
      <img src={image} style={{ width: "100%" }} />
      <div className={s.prognosisDates}>
        {prognosisDates.map((date, index) => (
          <span key={index}>{date}</span>
        ))}
      </div>
      <div className={s.prognosisTarget}>
        {goal_label}
        <br />
        {(threeMonths ? 75 : 150 * 2.205).toFixed(0) + "lb"}
      </div>
    </div>
  );
};

export default PrognosisImageV2;

import React from "react";
import iphoneFrame from "../../assets/iphone_frame.png";
import { Box } from "@mui/material";
import Timer from "../blocks/Timer";
import Image from "../blocks/Image";
import Plans from "../blocks/Plans";

interface Block {
  type: string;
  parameters: Record<string, unknown>;
}

interface PaywallPreviewProps {
  blocks: Block[];
}

const PaywallPreview: React.FC<PaywallPreviewProps> = ({ blocks }) => {
  const renderBlock = (block: Block, index: number) => {
    switch (block.type) {
      case "Timer":
        return <Timer key={index} {...(block.parameters as any)} />;
      case "Image":
        return <Image key={index} {...(block.parameters as any)} />;
      case "Plans":
        return <Plans key={index} {...(block.parameters as any)} />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        transform: `scale(${0.9},${0.9})`,
      }}
    >
      <Box
        sx={{
          backgroundImage: "url(" + iphoneFrame + ")",
          backgroundSize: "354px 699px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "354px",
          height: "699px",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            borderBottomLeftRadius: "42px",
            borderBottomRightRadius: "42px",
            overflow: "scroll",
            width: "294px",
            height: "604px",
            marginBottom: "29px",
          }}
        >
          {blocks.map((block, index) => renderBlock(block, index))}
        </Box>
      </Box>
    </Box>
  );
};

export default PaywallPreview;

import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import "./index.css";
import RoutesIndex from "./routes";
import theme from "./utils/theme";
import * as Sentry from "@sentry/react";

if (import.meta.env.VITE_CURRENT_ENVIRONMENT !== "dev") {
  Sentry.init({
    environment: import.meta.env.VITE_CURRENT_ENVIRONMENT,
    dsn: "https://86efad452c05a79786816dd7bb3f4ce7@o4505505369620480.ingest.sentry.io/4506616651776000",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "bottom", // or 'top'
        horizontal: "right" // This will be the default position for all snackbars
      }}>
      <ThemeProvider theme={theme}>
        <RoutesIndex />
      </ThemeProvider>
    </SnackbarProvider>
  </React.StrictMode>
);

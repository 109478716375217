import React from "react";

interface ImageProps {
  fill: "cover" | "contain";
  image: string;
  padding: string;
}

const Image: React.FC<ImageProps> = ({ fill, image, padding }) => {
  return (
    <div style={{ padding }}>
      <img
        src={image}
        style={{ objectFit: fill, width: "100%", height: "100%" }}
        alt="Block image"
      />
    </div>
  );
};

export default Image;

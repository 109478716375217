import React, { useState } from "react";
import { Box, Button, Popover, Typography } from "@mui/material";
import { SketchPicker } from "react-color";

interface ColorChooserProps {
  label: string;
  value: string;
  onChange: (color: string) => void;
}

const ColorChooser: React.FC<ColorChooserProps> = ({
  label,
  value,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (color: any) => {
    onChange(color.hex);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography sx={{ fontSize: "14px" }}>{label}</Typography>
        <Button
          variant="outlined"
          onClick={handleOpen}
          sx={{
            minWidth: 0,
            width: 36,
            height: 36,
            borderRadius: "50%",
            backgroundColor: value || "transparent",
            border: "1px solid #ccc",
            position: "relative",
            "&::after": {
              content: '""',
              display: value ? "none" : "block",
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "80%",
              height: "2px",
              backgroundColor: "black",
              opacity: 0.5,
              transform: "translate(-50%, -50%) rotate(45deg)",
            },
          }}
        />
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <SketchPicker color={value} onChange={handleColorChange} />
      </Popover>
    </Box>
  );
};

export default ColorChooser;

import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  collection,
  onSnapshot,
  doc,
  setDoc,
  deleteDoc,
  getDoc,
  query,
  getDocs,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { nanoid } from "nanoid";

import { Funnel, Paywall, Project } from "../../types";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import { MoreVert, Settings } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import app from "../../utils/firebase";

const ProjectPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const notistack = useSnackbar();
  const { projectId } = params;
  const [user, setUser] = useState<User>();
  const [project, setProject] = useState<Project>();
  const [funnels, setFunnels] = useState<Funnel[]>([]);
  const [paywalls, setPaywalls] = useState<Paywall[]>([]);
  const [loading, setLoading] = useState(false);

  const FUNNEL_MENUS = [
    {
      label: "Rename",
      value: "rename",
      action: (funnel: Funnel) => {
        openRenameFunnelDialog(funnel);
      },
    },
    {
      label: "Duplicate",
      value: "duplicate",
      action: (funnel: Funnel) => {
        if (
          window.confirm(
            "Are you sure you want to duplicate the funnel " + funnel.name
          )
        ) {
          if (funnel.id) {
            duplicateFunnel(funnel.id);
          }
        }
      },
    },
    {
      label: "Delete",
      value: "delete",
      action: (funnel: Funnel) => {
        if (
          window.confirm(
            "Are you sure you want to remove the funnel " + funnel.name
          )
        ) {
          if (funnel.id) {
            removeFunnel(funnel.id);
          }
        }
      },
    },
  ];

  const PAYWALL_MENUS = [
    {
      label: "Rename",
      value: "rename",
      action: (paywall: Paywall) => {
        openRenamePaywallDialog(paywall);
      },
    },
    {
      label: "Duplicate",
      value: "duplicate",
      action: (paywall: Paywall) => {
        if (
          window.confirm(
            "Are you sure you want to duplicate the paywall " + paywall.name
          )
        ) {
          if (paywall.id) {
            duplicatePaywall(paywall.id);
          }
        }
      },
    },
    {
      label: "Delete",
      value: "delete",
      action: (paywall: Paywall) => {
        if (
          window.confirm(
            "Are you sure you want to remove the paywall " + paywall.name
          )
        ) {
          if (paywall.id) {
            removePaywall(paywall.id);
          }
        }
      },
    },
  ];

  const syncProject = (user: User, projectId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const projectRef = doc(db, "projects", user.uid, "projects", projectId);
      onSnapshot(projectRef, (snapshot) => {
        const project = { ...snapshot.data(), id: snapshot.id };
        setProject(project as Project);
        setLoading(false);
      });
    } catch (error) {
      setProject(undefined);
      setLoading(false);
    }
  };

  const syncFunnels = async (user: User, projectId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const funnelsRef = collection(
        db,
        "projects",
        user.uid,
        "projects",
        projectId,
        "funnels"
      );
      const q = query(funnelsRef, orderBy("name"));
      const querySnapshot = await getDocs(q);

      const funnels = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setFunnels(funnels as Funnel[]);
      setLoading(false);
    } catch (error) {
      setFunnels([]);
      setLoading(false);
    }
  };

  async function duplicateDocument(
    sourceDocumentPath: string,
    sourceDocumentId: string,
    targetDocumentPath: string,
    changeName = false
  ) {
    const db = getFirestore();
    try {
      // Fetch the source document
      console.log(
        "Duplicate from " + sourceDocumentPath + " to " + targetDocumentPath
      );
      const sourceDocumentSnapshot = await getDoc(doc(db, sourceDocumentPath));

      if (!sourceDocumentSnapshot.exists) {
        console.error(`Source document ${sourceDocumentId} does not exist.`);
        return;
      }

      const sourceData = sourceDocumentSnapshot.data();
      console.log("Source data", sourceData);
      // Create a new document in the target collection
      await setDoc(doc(db, targetDocumentPath), {
        ...sourceData,
        name: changeName ? sourceData?.name + " Copy" : sourceData?.name,
      });

      const funnelScreensRef = collection(db, `${sourceDocumentPath}/screens`);
      const qScreens = query(funnelScreensRef);
      const funnelScreensQuerySnapshot = await getDocs(qScreens);
      funnelScreensQuerySnapshot.docs.forEach(async (screenDocument) => {
        const newScreenId = nanoid();
        await duplicateDocument(
          `${sourceDocumentPath}/screens/${screenDocument.id}`,
          screenDocument.id,
          `${targetDocumentPath}/screens/${newScreenId}`
        );
      });

      console.log("Document duplicated successfully.");
    } catch (error) {
      console.error("Error duplicating document:", error);
    }
  }

  const duplicatePaywall = useCallback(
    async (paywallId: string) => {
      if (user && projectId) {
        try {
          setLoading(true);
          const newId = nanoid();
          duplicateDocument(
            "projects/" +
              user.uid +
              "/projects/" +
              projectId +
              "/paywalls/" +
              paywallId,
            paywallId,
            "projects/" +
              user.uid +
              "/projects/" +
              projectId +
              "/paywalls/" +
              newId,
            true
          );
          handleMenuClose();
          handleClose();
          syncPaywalls(user, projectId);
        } catch (error) {
          console.error("Error duplicating paywall:", error);
          setLoading(false);
        }
      }
    },
    [user]
  );

  const duplicateFunnel = useCallback(
    async (funnelId: string) => {
      if (user && projectId) {
        try {
          setLoading(true);
          const newId = nanoid();
          duplicateDocument(
            "projects/" +
              user.uid +
              "/projects/" +
              projectId +
              "/funnels/" +
              funnelId,
            funnelId,
            "projects/" +
              user.uid +
              "/projects/" +
              projectId +
              "/funnels/" +
              newId,
            true
          );
          handleMenuClose();
          handleClose();
          syncFunnels(user, projectId);
        } catch (error) {
          console.error("Error duplicating funnel:", error);
          setLoading(false);
        }
      }
    },
    [user]
  );

  const syncPaywalls = async (user: User, projectId: string) => {
    const db = getFirestore();
    try {
      setLoading(true);
      const paywallsRef = collection(
        db,
        "projects",
        user.uid,
        "projects",
        projectId,
        "paywalls"
      );
      const q = query(paywallsRef, orderBy("name"));
      const querySnapshot = await getDocs(q);

      const paywalls = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setPaywalls(paywalls as Paywall[]);
      setLoading(false);
    } catch (error) {
      setPaywalls([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user != null && projectId) {
        setUser(user);
        syncProject(user, projectId);
        syncFunnels(user, projectId);
        syncPaywalls(user, projectId);
      }
    });
  }, []);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [funnel, setFunnel] = useState<Funnel>({ name: "" });
  const [paywall, setPaywall] = useState<Partial<Paywall>>({ name: "" });

  const openRenameFunnelDialog = useCallback((funnel: Funnel) => {
    setOpen(true);
    setFunnel(funnel);
  }, []);

  const closeRenameFunnelDialog = useCallback(() => {
    setOpen(false);
    setFunnel({ name: "" });
  }, []);

  const openRenamePaywallDialog = useCallback((paywall: Paywall) => {
    console.log("openRenamePaywallDialog", paywall);
    setOpen(true);
    setPaywall(paywall);
  }, []);

  const closeRenamePaywallDialog = useCallback(() => {
    setOpen(false);
    setPaywall((state) => ({ ...state, name: "" }));
  }, []);

  const removeFunnel = useCallback(
    async (funnelId: string) => {
      if (user && projectId) {
        const db = getFirestore();
        try {
          setLoading(true);

          const screensRef = collection(
            db,
            "projects",
            user.uid,
            "projects",
            projectId,
            "funnels",
            funnelId,
            "screens"
          );

          const q = query(screensRef, orderBy("name"));
          const screensQuerySnapshot = await getDocs(q);

          screensQuerySnapshot.docs.forEach(async (screenDocument) => {
            await deleteDoc(
              doc(
                db,
                "projects",
                user.uid,
                "projects",
                projectId,
                "funnels",
                funnelId,
                "screens",
                screenDocument.id
              )
            );
          });

          await deleteDoc(
            doc(
              db,
              "projects",
              user.uid,
              "projects",
              projectId,
              "funnels",
              funnelId
            )
          );
          handleMenuClose();
          handleClose();
          syncFunnels(user, projectId);
        } catch (error) {
        } finally {
          setLoading(false);
        }
      }
    },
    [user, projectId]
  );

  const renameFunnel = useCallback(async () => {
    if (user && projectId && funnel && funnel.id) {
      const db = getFirestore();
      try {
        setLoading(true);

        await updateDoc(
          doc(
            db,
            "projects",
            user.uid,
            "projects",
            projectId,
            "funnels",
            funnel.id
          ),
          {
            name: funnel.name,
          }
        );
        closeRenameFunnelDialog();
        notistack.enqueueSnackbar("Funnel renamed!", { variant: "success" });
        syncFunnels(user, projectId);
      } catch (error) {
        console.error(error);
        notistack.enqueueSnackbar("Failed to rename funnel!", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [user, projectId, funnel, closeRenameFunnelDialog, notistack]);

  const createFunnel = useCallback(async () => {
    if (user && projectId) {
      const db = getFirestore();
      try {
        setLoading(true);
        await setDoc(
          doc(
            db,
            "projects",
            user.uid,
            "projects",
            projectId,
            "funnels",
            nanoid()
          ),
          {
            name: funnel.name,
          }
        );
        handleClose();
        syncFunnels(user, projectId);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  }, [user, funnel, projectId]);

  const removePaywall = useCallback(
    async (paywallId: string) => {
      if (user && projectId) {
        const db = getFirestore();
        try {
          setLoading(true);
          await deleteDoc(
            doc(
              db,
              "projects",
              user.uid,
              "projects",
              projectId,
              "paywalls",
              paywallId
            )
          );
          handleMenuClose();
          handleClose();
          syncPaywalls(user, projectId);
        } catch (error) {
        } finally {
          setLoading(false);
        }
      }
    },
    [user, projectId]
  );

  const renamePaywall = useCallback(async () => {
    if (user && projectId && paywall && paywall.id) {
      console.log("Rename paywall", paywall);
      const db = getFirestore();
      try {
        setLoading(true);

        await updateDoc(
          doc(
            db,
            "projects",
            user.uid,
            "projects",
            projectId,
            "paywalls",
            paywall.id
          ),
          {
            name: paywall.name,
          }
        );
        handleMenuClose();
        closeRenamePaywallDialog();
        notistack.enqueueSnackbar("Paywall renamed!", { variant: "success" });
        syncPaywalls(user, projectId);
      } catch (error) {
        console.error(error);
        notistack.enqueueSnackbar("Failed to rename paywall!", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [user, projectId, paywall, closeRenamePaywallDialog, notistack]);

  const createPaywall = useCallback(async () => {
    if (user && projectId) {
      const db = getFirestore();
      try {
        setLoading(true);
        await setDoc(
          doc(
            db,
            "projects",
            user.uid,
            "projects",
            projectId,
            "paywalls",
            nanoid()
          ),
          {
            name: paywall.name,
          }
        );
        handleClose();
        syncPaywalls(user, projectId);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  }, [user, paywall, projectId]);

  const [isDeploying, setIsDeploying] = useState(false);
  const handleDeployProject = () => {
    const functions = getFunctions(app);
    const deployProject = httpsCallable(functions, "deployProjectById");
    setIsDeploying(true);

    const params = { projectId } as Record<string, string>;
    // if (projectId === "ZkLJec-j3r89onHcFiXkM") {
    //   params.versionId = "hardcoded_paywalls";
    // }
    deployProject(params)
      .then((result) => {
        const data = result.data;
        console.log("Result", data);
        setIsDeploying(false);
        notistack.enqueueSnackbar("Successfully started", {
          variant: "success",
        });
      })
      .catch((e) => {
        setIsDeploying(false);
        notistack.enqueueSnackbar("Cannot deploy project: " + e, {
          variant: "error",
        });
      });
  };

  const [activeArea, setActiveArea] = useState<"funnels" | "paywalls">(
    "funnels"
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openEl, setOpenEl] = useState("");
  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    projectName: string
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpenEl(projectName);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenEl("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Button
        size="small"
        sx={{ position: "absolute", right: "0", top: "0", height: "24px" }}
        onClick={isDeploying ? undefined : handleDeployProject}
      >
        {isDeploying ? (
          <CircularProgress size={13} sx={{ color: "#fff" }} />
        ) : (
          "Deploy"
        )}
      </Button>
      <Typography variant="h1">
        <Box
          component="span"
          sx={{ color: "#666", cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        >
          Projects {">"}
        </Box>
        <Box component="span" sx={{ color: "#282828" }}>
          {" "}
          {project?.name}
        </Box>
        <IconButton onClick={() => navigate("settings")}>
          <Settings />
        </IconButton>
      </Typography>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        sx={{ height: "32px", marginTop: "24px" }}
      >
        <Button
          sx={{
            backgroundColor: activeArea === "funnels" ? "gray" : "#bdbaba",
          }}
          onClick={() => setActiveArea("funnels")}
        >
          Onboardings
        </Button>
        <Button
          sx={{
            backgroundColor: activeArea === "paywalls" ? "gray" : "#bdbaba",
          }}
          onClick={() => setActiveArea("paywalls")}
        >
          Paywalls
        </Button>
      </ButtonGroup>
      {activeArea === "funnels" && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "24px",
              marginBottom: "64px",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : !funnels || funnels.length === 0 ? (
              <Typography variant="body1" sx={{ marginTop: "24px" }}>
                🎈 Start by adding your first funnel...
              </Typography>
            ) : (
              <Grid container gap={1}>
                {funnels.map((funnel, index) => {
                  const open = openEl === funnel.name;
                  return (
                    <Grid
                      item
                      key={index}
                      sx={{
                        backgroundColor: "#007CEE",
                        borderRadius: "6px",
                        width: "181px",
                        height: "221px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        position: "relative",
                        "&:hover": {
                          backgroundColor: "#0065c2",
                        },
                      }}
                      onClick={() => {
                        navigate(
                          "/projects/" + projectId + "/funnels/" + funnel.id
                        );
                      }}
                    >
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleMenuClick(e, funnel.name);
                        }}
                        sx={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          color: "#fff",
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                      >
                        {FUNNEL_MENUS.map((menu, index) => (
                          <MenuItem
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              if (menu.action) {
                                menu.action(funnel as Funnel);
                              }
                            }}
                          >
                            {menu.label}
                          </MenuItem>
                        ))}
                      </Menu>

                      <LocalFireDepartmentOutlinedIcon
                        sx={{ color: "white" }}
                      />
                      <Typography
                        variant="body1"
                        sx={{ color: "white", marginTop: "6px" }}
                      >
                        {funnel.name}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Box>
          <Button variant="contained" onClick={handleClickOpen}>
            + Add New
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              component: "form",
              onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                if (!funnel.id) {
                  createFunnel();
                } else {
                  renameFunnel();
                }
              },
            }}
          >
            <DialogTitle>
              {funnel.id ? "Rename Funnel" : "Add New Funnel"}
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                required
                value={funnel.name || ""}
                onChange={(e) =>
                  setFunnel((funnel) => ({ ...funnel, name: e.target.value }))
                }
              />
            </DialogContent>
            <DialogActions>
              <Button disabled={loading} variant="contained" type="submit">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}

      {activeArea === "paywalls" && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "24px",
              marginBottom: "64px",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : !paywalls || paywalls.length === 0 ? (
              <Typography variant="body1" sx={{ marginTop: "24px" }}>
                🎈 Start by adding your first paywall...
              </Typography>
            ) : (
              <Grid container gap={1}>
                {paywalls.map((paywall, index) => {
                  const open = openEl === paywall.name;
                  return (
                    <Grid
                      item
                      key={index}
                      sx={{
                        backgroundColor: "#007CEE",
                        borderRadius: "6px",
                        width: "181px",
                        height: "221px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        position: "relative",
                        "&:hover": {
                          backgroundColor: "#0065c2",
                        },
                      }}
                      onClick={() => {
                        navigate(
                          "/projects/" + projectId + "/paywalls/" + paywall.id
                        );
                      }}
                    >
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleMenuClick(e, paywall.name);
                        }}
                        sx={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          color: "#fff",
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                      >
                        {PAYWALL_MENUS.map((menu, index) => (
                          <MenuItem
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              if (menu.action) {
                                menu.action(paywall as Paywall);
                              }
                            }}
                          >
                            {menu.label}
                          </MenuItem>
                        ))}
                      </Menu>

                      <LocalFireDepartmentOutlinedIcon
                        sx={{ color: "white" }}
                      />
                      <Typography
                        variant="body1"
                        sx={{ color: "white", marginTop: "6px" }}
                      >
                        {paywall.name}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Box>
          <Button variant="contained" onClick={handleClickOpen}>
            + Add New
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              component: "form",
              onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();

                if (!paywall.id) {
                  createPaywall();
                } else {
                  renamePaywall();
                }
              },
            }}
          >
            <DialogTitle>
              {paywall.id ? "Rename Paywall" : "Add New Paywall"}
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                value={paywall.name || ""}
                onChange={(e) =>
                  setPaywall((paywall) => ({
                    ...paywall,
                    name: e.target.value,
                  }))
                }
              />
            </DialogContent>
            <DialogActions>
              <Button disabled={loading} variant="contained" type="submit">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default ProjectPage;
